import React from 'react';
import footerImage from '../../images/footer-image.jpg';
import styled from 'styled-components';
import { MainLogo } from '../icons';
import classes from './Footer.module.css';

const DesignedBy = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: white;
`;

export default function Footer() {
  return (
    <footer className={classes.footer}>
      <div className={classes.imageContainer}>
        <img src={footerImage} style={{ objectFit: 'cover', width: '100%' }} alt={'footer-image'} />
      </div>
      <MainLogo />
      <DesignedBy>© Разработано в ABC DESIGN - создание сайтов в Астане</DesignedBy>
    </footer>
  );
}
