import { useEffect, useState } from 'react';

export default function useWindow() {
  const [_window, set_window] = useState<Window | null>(null);

  useEffect(() => {
    set_window(window);
  }, []);

  return { window: _window };
}
