import styled from 'styled-components';
import { MAX_WIDTH } from '../constants';

const Layout = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
`;

export default Layout;
