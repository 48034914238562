import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useWindow } from '../hooks';

const ChevronUp = () => {
  return (
    <svg width={36} height={36} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 10.0606L6.53039 15.5303L5.46973 14.4696L12.0001 7.93928L18.5304 14.4696L17.4697 15.5303L12.0001 10.0606Z"
        fill={'white'}
      />
    </svg>
  );
};

const StyledScrollToTopFab = styled.div`
  position: fixed;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 20px;
  height: 50px;
  width: 50px;
  border-radius: 60px;
  background-color: #008dd2;
  z-index: 100;
  transform: translateY(70px);
  transition: transform 0.3s ease 0s;
  &.isVisible {
    transform: translateY(0);
  }
`;

export default function ScrollToTopFab() {
  const { window } = useWindow();
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    if (window) {
      const onScroll = () => {
        if (window.scrollY > window.screen.height - 300) {
          setShowScrollToTop(true);
        } else {
          setShowScrollToTop(false);
        }
      };
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [window]);

  return (
    <StyledScrollToTopFab
      className={showScrollToTop ? 'isVisible' : ''}
      onClick={() => window && window.scrollTo({ top: 0, behavior: 'smooth' })}>
      <ChevronUp />
    </StyledScrollToTopFab>
  );
}
