import React, { useRef } from 'react';
import { BlockSeparator, Column, Layout } from '../../../../components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import img1 from '../../../../images/partners/part1.png';
import img2 from '../../../../images/partners/part2.png';
import img3 from '../../../../images/partners/part3.png';
import img4 from '../../../../images/partners/part4.png';
import img5 from '../../../../images/partners/part5.png';
import img6 from '../../../../images/partners/part6.jpg';
import img7 from '../../../../images/partners/part7.png';
import AliceCarousel from 'react-alice-carousel';
import next from '../../../../images/next.png';
import classes from './Partners.module.css';

const images = [img1, img2, img3, img4, img5, img6, img7];

export default function Partners() {
  const ref = useRef(null);

  return (
    <Column style={{ marginBottom: 70 }}>
      <Layout>
        <BlockSeparator title={'ПАРТНЕРЫ'} />
      </Layout>

      <div className={classes.carouselContainer}>
        <Layout>
          <AliceCarousel
            ref={ref}
            infinite
            disableButtonsControls
            disableDotsControls
            autoWidth
            items={images.map((i, index) => {
              const key = 'img' + index;

              return (
                <div key={key} style={{ width: 200 }}>
                  <img className={classes.image} src={i} alt={key} />
                </div>
              );
            })}
          />
        </Layout>
        <Column
          justifyContent={'center'}
          alignItems={'center'}
          onClick={() => ref.current && (ref.current as any).slidePrev()}
          className={[classes.arrowButton, classes.arrowButtonLeft].join(' ')}>
          <img
            src={next}
            alt={'prev'}
            style={{ height: 32, width: 16, objectFit: 'contain', transform: 'scaleX(-1)' }}
          />
        </Column>
        <Column
          justifyContent={'center'}
          alignItems={'center'}
          onClick={() => ref.current && (ref.current as any).slideNext()}
          className={[classes.arrowButton, classes.arrowButtonRight].join(' ')}>
          <img src={next} alt={'next'} style={{ height: 32, width: 16, objectFit: 'contain' }} />
        </Column>
      </div>
    </Column>
  );
}
