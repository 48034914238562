import React from 'react';
import classes from '../home.module.css';
import { Column, Layout, MainLogo } from '../../../components';
import downArrow from '../../../images/down-arrow.png';
import { useWindow } from '../../../hooks';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import background from '../../../images/main.jpg';

const YVideoItem = (props: { id: string }) => {
  const { id } = props;

  return (
    <Column style={{ width: '100vw' }}>
      <iframe
        src={`https://www.youtube.com/embed/${id}?autoplay=1&amp;loop=1&amp;enablejsapi=1&amp;playerapiid=featuredytplayer&amp;controls=0&amp;modestbranding=1&amp;rel=0&amp;showinfo=0&amp;color=white&amp;iv_load_policy=3&amp;theme=light&amp;wmode=transparent&amp;&amp;playlist=${id}&amp;mute=1`}
        frameBorder="0"
        allow="fullscreen; autoplay; loop"
        id="widget2"
        className={classes.iframe}
        data-gtm-yt-inspected-8="true"
        title="Wen.kz"
        data-gtm-yt-inspected-16="true"></iframe>
    </Column>
  );
};

const youtubeVideoIds = ['huciyN2_Xlg', 'Fy73a-my3_Y', 'D22DnSQuJGU', 'Du5iUCzBfOs'];

const youtubeItems = youtubeVideoIds.map((id: string, index) => (
  <YVideoItem key={`youtube${index}`} id={id} />
));

export default function Header() {
  const { window } = useWindow();

  return (
    <Column justifyContent={'space-between'} className={classes.header}>
      <Column className={classes.fillImageBackground}>
        <img alt={''} className={classes.mainImage} src={background} />
        <AliceCarousel
          autoWidth
          items={youtubeItems}
          disableDotsControls
          disableButtonsControls
          autoPlay
          autoPlayInterval={10000}
          infinite
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            background: 'rgba(0,0,0,0.7)'
          }}
        />
      </Column>

      <Column alignItems={'center'}>
        <MainLogo />
      </Column>

      <Layout>
        <Column alignItems={'center'}>
          <div className={classes.headerTitle}>ТОО «Water Engineering»</div>
          <div className={classes.headerSubtitle}>
            Профессиональный подход в очистке сточных вод и водоподготовке
          </div>
        </Column>
      </Layout>

      <Column
        gap={15}
        alignItems={'center'}
        onClick={() =>
          window && window.scrollBy({ top: window.screen.height, behavior: 'smooth' })
        }>
        <div className={classes.downArrowText}>Прокрутить вниз</div>
        <img src={downArrow} alt={'down-arrow'} />
      </Column>
    </Column>
  );
}
