import React, { useRef } from 'react';
import styled from 'styled-components';
import { BlockSeparator, Column, Layout } from '../../../components';
import icon1 from '../../../images/services/icn1.png';
import icon2 from '../../../images/services/icn2.png';
import icon3 from '../../../images/services/icn3.png';
import icon4 from '../../../images/services/icn4.png';
import icon5 from '../../../images/services/icn5.png';
import icon6 from '../../../images/services/icn6.png';
import icon7 from '../../../images/services/icn7.png';
import icon8 from '../../../images/services/icn8.png';
import icon9 from '../../../images/services/icn9.png';
import { LAPTOP_WIDTH, MOBILE_WIDTH, TABLET_WIDTH } from '../../../constants';

type IService = {
  title: string;
  description: string;
  image: any;
  color: string;
};

const services: IService[] = [
  {
    image: icon1,
    color: '#008DD2',
    title: 'Проектирование',
    description:
      'Компания «Water Engineering» выполняет технологическое и рабочее проектирование объектов строительства и реконструкции водоочистных сооружений.'
  },
  {
    image: icon2,
    color: '#32B5AD',
    title: 'Подбор насосов',
    description:
      'Вы можете подобрать оборудование по параметрам, указав необходимые Вам подачу и напор насоса или насосной установки.'
  },
  {
    image: icon3,
    color: '#057436',
    title: 'Производство и изготовление',
    description: 'Изготовление изделий из армированного стеклопластика и стали.'
  },
  {
    image: icon4,
    color: '#32B5AD',
    title: 'Доставка',
    description:
      '«Water Engineering» может произвести доставку до объекта любым видом транспорта с оформлением всех необходимых разрешений.'
  },
  {
    image: icon5,
    color: '#008DD2',
    title: 'Шеф-монтаж',
    description:
      'Специалисты компании «Water Engineering» готовы на объекте Заказчика оказывать техническое сопровождение производителю работ.'
  },
  {
    image: icon6,
    color: '#057436',
    title: 'Монтаж',
    description:
      'Компания «Water Engineering» готова грамотно и в сжатые сроки осуществить комплекс работ по монтажу технологического оборудования с применением современных решений.'
  },
  {
    image: icon7,
    color: '#008DD2',
    title: 'Пуско-наладка',
    description:
      'Для успешной эксплуатации сложного и дорогостоящего оборудования наши специалисты производят комплекс работ по пуско-наладке систем и оборудования.'
  },
  {
    image: icon8,
    color: '#057436',
    title: 'Обучение персонала',
    description:
      'Проводит курсы обучения и повышения квалификации специалистов, эксплуатирующих водоочистные сооружения в рамках проведения пусконаладочных работ и по заявкам.'
  },
  {
    image: icon9,
    color: '#32B5AD',
    title: 'Сервисное обслуживание',
    description:
      'На все оборудование, поставляемое «Water Engineering», действует заводская гарантия.'
  }
];

const Container = styled(Layout)`
  padding: 90px 15px 0 15px;
  margin-bottom: 90px;
  flex-direction: column;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  @media (max-width: ${LAPTOP_WIDTH}px) {
    gap: 20px;
  }
  @media (max-width: ${TABLET_WIDTH}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${MOBILE_WIDTH}px) {
    grid-template-columns: repeat(1, 1fr);
  }
  padding-top: 40px;
  margin-bottom: 90px;
`;

const Item = styled.div`
  border: 1px solid #008dd2;
  overflow: hidden;
  height: 325px;
`;

const ItemCover = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 35px 25px;
  gap: 30px;
  justify-content: space-between;
`;

const ItemContent = styled.div`
  background-color: #008dd2;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 25px;
`;

const Title = styled.div`
  font-size: 20px;
  line-height: 29px;
  font-weight: bold;
`;
const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: white;
`;
const ShowMoreText = styled.div<{ $color: string }>`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: ${(props: any) => props.$color};
`;

function Service({ item }: { item: IService }) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Item
      ref={ref}
      onMouseEnter={() => {
        if (ref.current) {
          ref.current.scrollTo({
            top: ref.current.clientHeight,
            behavior: 'smooth'
          });
        }
      }}
      onMouseLeave={() => {
        if (ref.current) {
          ref.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }}>
      <ItemCover>
        <img src={item.image} alt={item.title} style={{ width: 76, aspectRatio: 1 }} />
        <Column gap={16}>
          <Title>{item.title}</Title>
          <ShowMoreText $color={item.color}>Узнать подробнее →</ShowMoreText>
        </Column>
      </ItemCover>
      <ItemContent>
        <Description>{item.description}</Description>
      </ItemContent>
    </Item>
  );
}

export default function Services() {
  return (
    <Container>
      <BlockSeparator title={'УСЛУГИ'} />
      <Grid>
        {services.map((i) => {
          return <Service key={i.title} item={i} />;
        })}
      </Grid>
    </Container>
  );
}
