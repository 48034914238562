import React, { useRef, useState } from 'react';
import { BlockSeparator, Column, Layout } from '../../../../components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import img1 from '../../../../images/certificates/1.jpg';
import img2 from '../../../../images/certificates/2.jpg';
import img3 from '../../../../images/certificates/3.jpg';
import img4 from '../../../../images/certificates/4.jpg';
import img5 from '../../../../images/certificates/5.jpg';
import img6 from '../../../../images/certificates/6.jpg';
import AliceCarousel from 'react-alice-carousel';
import next from '../../../../images/next.png';
import classes from './Certificates.module.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ImgsViewer from 'react-images-viewer';

const images = [img1, img2, img3, img4, img5, img6];

export default function Certificates() {
  const ref = useRef(null);

  const [image, setImage] = useState<string>();

  return (
    <Column style={{ marginBottom: 70 }}>
      <Layout>
        <BlockSeparator title={'СЕРТИФИКАТЫ'} />
      </Layout>

      <div style={{ position: 'relative', background: '#f7f7f7', padding: '35px 0' }}>
        <Layout>
          <AliceCarousel
            ref={ref}
            infinite
            disableButtonsControls
            disableDotsControls
            autoWidth
            items={images.map((i, index) => {
              const key = 'img' + index;

              return (
                <div key={key} style={{ width: 200 }}>
                  <img
                    className={classes.image}
                    src={i}
                    alt={key}
                    onClick={() => {
                      setImage(i);
                    }}
                  />
                </div>
              );
            })}
          />
        </Layout>
        <Column
          justifyContent={'center'}
          alignItems={'center'}
          onClick={() => ref.current && (ref.current as any).slidePrev()}
          className={[classes.arrowButton, classes.arrowButtonLeft].join(' ')}>
          <img
            src={next}
            alt={'prev'}
            style={{ height: 32, width: 16, objectFit: 'contain', transform: 'scaleX(-1)' }}
          />
        </Column>
        <Column
          justifyContent={'center'}
          alignItems={'center'}
          onClick={() => ref.current && (ref.current as any).slideNext()}
          className={[classes.arrowButton, classes.arrowButtonRight].join(' ')}>
          <img src={next} alt={'next'} style={{ height: 32, width: 16, objectFit: 'contain' }} />
        </Column>
      </div>

      <ImgsViewer
        imgs={[{ src: image }]}
        currImg={0}
        isOpen={Boolean(image)}
        onClose={() => setImage(undefined)}
        backdropCloseable
      />
    </Column>
  );
}
