import React, { useMemo } from 'react';
import { FlexProps } from './types';
import classes from './Flex.module.css';

export default function Column(props: FlexProps) {
  const {
    direction = 'column',
    className: _className,
    gap,
    wrap = false,
    style,
    children,
    justifyContent,
    alignItems,
    ...restProps
  } = props;

  const className = useMemo(() => {
    return [classes.flex, classes[direction], wrap && classes.wrap, _className]
      .filter(Boolean)
      .join(' ');
  }, [direction, wrap, _className]);

  return (
    <div className={className} style={{ alignItems, justifyContent, gap, ...style }} {...restProps}>
      {children}
    </div>
  );
}
