import React from 'react';
import styled, { css } from 'styled-components';
import { LAPTOP_WIDTH, TABLET_WIDTH } from '../constants';
import classes from '../screens/home/home.module.css';
import Layout from './Layout';
import { Column } from './Flex';

const Container = styled(Layout)`
  padding: 90px 15px 0 15px;
`;

const Content = styled.div<{ $reversed?: boolean }>`
  display: flex;
  ${(props) =>
    props.$reversed
      ? css`
          flex-direction: row-reverse;
        `
      : css`
          flex-direction: row;
        `}
  align-items: center;
  gap: 78px;
  @media (max-width: ${TABLET_WIDTH}px) {
    gap: 20px;
    flex-direction: column;
  }
`;

const StyledImage = styled.img`
  object-fit: contain;
  aspect-ratio: 1.4;
  max-width: 478px;
  @media (max-width: ${LAPTOP_WIDTH}px) {
    max-width: 367px;
  }
  @media (max-width: ${TABLET_WIDTH}px) {
    max-width: 510px;
    width: 100%;
    margin: 0 auto;
  }
`;

type PhotoPosition = 'left' | 'right';

type Props = {
  title: string;
  description: string;
  photo: any;
  photoPosition?: PhotoPosition;
};

export default function ParagraphWithPhoto(props: Props) {
  const { photoPosition = 'right', photo, description, title } = props;

  return (
    <Container>
      <Content $reversed={photoPosition === 'left'}>
        <Column gap={30}>
          <div className={classes.title}>{title}</div>
          <div className={classes.text}>{description}</div>
        </Column>
        <StyledImage src={photo} alt={'main-1'} />
      </Content>
    </Container>
  );
}
