import React, { SVGProps } from 'react';
import styled from 'styled-components';

function Logo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 164 90" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M65.9555 47.802L60.6422 66.6175H58.0574L54.1823 52.8687C54.0165 52.2819 53.918 51.6413 53.8821 50.9513H53.8283C53.7791 51.5965 53.6626 52.2282 53.4879 52.8419L49.5904 66.6175H47.0324L41.5176 47.802H43.9457L47.9508 62.2362C48.1165 62.8365 48.2195 63.4681 48.2643 64.1223H48.3316C48.3718 63.6608 48.5107 63.0292 48.7392 62.2362L52.8965 47.802H55.0111L59.0026 62.3393C59.1415 62.8365 59.2445 63.4234 59.3162 64.0999H59.37C59.4013 63.6429 59.5223 63.0381 59.7239 62.29L63.5676 47.802H65.9555Z"
        fill="#0B83B2"></path>
      <path
        d="M82.2439 66.6175H79.8024L77.8088 61.3492H69.8256L67.9485 66.6175H65.498L72.7152 47.802H74.9999L82.2439 66.6175ZM77.0831 59.3601L74.1308 51.3456C74.0368 51.0858 73.9382 50.6646 73.8441 50.0868H73.7904C73.7052 50.6199 73.6022 51.0409 73.4902 51.3456L70.5603 59.3601H77.0831Z"
        fill="#0B83B2"></path>
      <path
        d="M95.1956 49.7911H89.7615V66.6175H87.5574V49.7911H82.1367V47.802H95.1956V49.7911Z"
        fill="#0B83B2"></path>
      <path
        d="M108.124 66.6175H98.1519V47.802H107.708V49.7911H100.356V56.054H107.156V58.0431H100.356V64.6285H108.124V66.6175Z"
        fill="#0B83B2"></path>
      <path
        d="M125.242 66.6175H122.617L119.463 61.3403C119.176 60.8474 118.898 60.4308 118.625 60.0858C118.352 59.7409 118.074 59.4587 117.792 59.2391C117.505 59.0196 117.201 58.8583 116.874 58.7598C116.546 58.6568 116.175 58.6075 115.763 58.6075H113.953V66.6175H111.749V47.802H117.366C118.186 47.802 118.948 47.905 119.642 48.1111C120.337 48.3172 120.941 48.6263 121.452 49.0474C121.967 49.4685 122.366 49.9882 122.653 50.6154C122.944 51.2426 123.087 51.9727 123.087 52.8105C123.087 53.4691 122.989 54.0694 122.791 54.6159C122.594 55.1625 122.317 55.6507 121.954 56.0764C121.591 56.5065 121.152 56.8693 120.641 57.1739C120.126 57.4741 119.553 57.707 118.912 57.8728V57.9265C119.23 58.0654 119.503 58.2267 119.736 58.4059C119.965 58.5851 120.189 58.8002 120.395 59.0421C120.605 59.2885 120.816 59.5661 121.022 59.8753C121.228 60.1889 121.456 60.5473 121.707 60.9595L125.242 66.6175ZM113.953 49.7911V56.6184H116.945C117.496 56.6184 118.007 56.5333 118.473 56.3676C118.943 56.2018 119.346 55.9643 119.687 55.6552C120.027 55.3416 120.296 54.9608 120.489 54.5129C120.681 54.0604 120.776 53.5542 120.776 52.9942C120.776 51.9863 120.453 51.2022 119.799 50.6378C119.149 50.0733 118.204 49.7911 116.972 49.7911H113.953Z"
        fill="#0B83B2"></path>
      <path
        d="M9.97221 89.1898H0V70.3743H9.55566V72.3634H2.20408V78.6262H9.00454V80.6153H2.20408V87.2007H9.97221V89.1898Z"
        fill="white"></path>
      <path
        d="M28.7701 89.1898H26.0642L16.3787 74.1911C16.1323 73.8149 15.9307 73.4205 15.7739 73.0129H15.6977C15.7649 73.4116 15.8008 74.2763 15.8008 75.5978V89.1898H13.5967V70.3743H16.4593L25.8805 85.1355C26.2748 85.7492 26.5301 86.1659 26.6421 86.3944H26.6959C26.6062 85.8523 26.5615 84.9295 26.5615 83.6258V70.3743H28.7701V89.1898Z"
        fill="white"></path>
      <path
        d="M47.7696 87.9036C45.8835 88.9742 43.7825 89.5119 41.4708 89.5119C38.7874 89.5119 36.6146 88.6472 34.9571 86.9136C33.2951 85.1798 32.4663 82.8861 32.4663 80.0324C32.4663 77.116 33.3891 74.7238 35.2304 72.8556C37.0716 70.9875 39.4057 70.0513 42.2324 70.0513C44.2797 70.0513 46.0001 70.3827 47.3888 71.0503V73.4917C45.8701 72.5241 44.0647 72.0403 41.9815 72.0403C39.876 72.0403 38.1468 72.766 36.7984 74.222C35.4499 75.6735 34.7779 77.5551 34.7779 79.8667C34.7779 82.25 35.405 84.1181 36.655 85.48C37.9049 86.8419 39.6027 87.5228 41.7486 87.5228C43.218 87.5228 44.4903 87.2316 45.5655 86.6447V81.3674H41.444V79.3783H47.7696V87.9036Z"
        fill="white"></path>
      <path d="M54.3457 89.1898H52.1416V70.3743H54.3457V89.1898Z" fill="white"></path>
      <path
        d="M74.4698 89.1898H71.764L62.0784 74.1911C61.832 73.8149 61.6304 73.4205 61.4736 73.0129H61.3974C61.4646 73.4116 61.5005 74.2763 61.5005 75.5978V89.1898H59.2964V70.3743H62.159L71.5802 85.1355C71.9745 85.7492 72.2298 86.1659 72.3418 86.3944H72.3956C72.306 85.8523 72.2612 84.9295 72.2612 83.6258V70.3743H74.4698V89.1898Z"
        fill="white"></path>
      <path
        d="M89.3741 89.1898H79.4019V70.3743H88.9575V72.3634H81.6059V78.6262H88.4064V80.6153H81.6059V87.2007H89.3741V89.1898Z"
        fill="white"></path>
      <path
        d="M102.97 89.1898H92.998V70.3743H102.554V72.3634H95.2021V78.6262H102.003V80.6153H95.2021V87.2007H102.97V89.1898Z"
        fill="white"></path>
      <path
        d="M120.088 89.1898H117.463L114.309 83.9125C114.022 83.4197 113.745 83.0031 113.471 82.6581C113.198 82.3132 112.92 82.031 112.638 81.8114C112.351 81.5919 112.047 81.4306 111.72 81.3321C111.393 81.229 111.021 81.1798 110.609 81.1798H108.799V89.1898H106.595V70.3743H112.213C113.032 70.3743 113.794 70.4773 114.488 70.6834C115.183 70.8894 115.788 71.1986 116.298 71.6197C116.813 72.0408 117.212 72.5604 117.499 73.1876C117.79 73.8149 117.933 74.545 117.933 75.3828C117.933 76.0414 117.835 76.6416 117.638 77.1882C117.441 77.7348 117.163 78.223 116.8 78.6487C116.437 79.0787 115.998 79.4416 115.487 79.7462C114.972 80.0464 114.399 80.2793 113.758 80.4451V80.4988C114.076 80.6377 114.349 80.799 114.582 80.9782C114.811 81.1573 115.035 81.3724 115.241 81.6143C115.451 81.8608 115.662 82.1384 115.868 82.4476C116.074 82.7611 116.303 83.1196 116.554 83.5317L120.088 89.1898ZM108.799 72.3634V79.1907H111.791C112.342 79.1907 112.853 79.1056 113.319 78.9398C113.789 78.774 114.193 78.5366 114.533 78.2275C114.874 77.9139 115.142 77.5331 115.335 77.0851C115.528 76.6326 115.622 76.1265 115.622 75.5664C115.622 74.5585 115.299 73.7745 114.645 73.2101C113.995 72.6455 113.05 72.3634 111.818 72.3634H108.799Z"
        fill="white"></path>
      <path d="M124.877 89.1898H122.673V70.3743H124.877V89.1898Z" fill="white"></path>
      <path
        d="M145.001 89.1898H142.295L132.61 74.1911C132.363 73.8149 132.162 73.4205 132.005 73.0129H131.929C131.996 73.4116 132.032 74.2763 132.032 75.5978V89.1898H129.828V70.3743H132.69L142.111 85.1355C142.506 85.7492 142.761 86.1659 142.873 86.3944H142.927C142.837 85.8523 142.792 84.9295 142.792 83.6258V70.3743H145.001V89.1898Z"
        fill="white"></path>
      <path
        d="M164 87.9036C162.114 88.9742 160.013 89.5119 157.701 89.5119C155.018 89.5119 152.845 88.6472 151.188 86.9136C149.526 85.1798 148.697 82.8861 148.697 80.0324C148.697 77.116 149.62 74.7238 151.461 72.8556C153.302 70.9875 155.636 70.0513 158.463 70.0513C160.51 70.0513 162.231 70.3827 163.619 71.0503V73.4917C162.101 72.5241 160.295 72.0403 158.212 72.0403C156.106 72.0403 154.377 72.766 153.029 74.222C151.68 75.6735 151.008 77.5551 151.008 79.8667C151.008 82.25 151.636 84.1181 152.885 85.48C154.135 86.8419 155.833 87.5228 157.979 87.5228C159.449 87.5228 160.721 87.2316 161.796 86.6447V81.3674H157.674V79.3783H164V87.9036Z"
        fill="white"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.711 0.847656C67.0818 24.7715 88.4624 30.4587 94.1522 20.3068C90.7345 12.9843 84.1183 6.55679 83.711 0.847656Z"
        fill="#057436"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.148 33.9898C82.0339 41.0621 59.8944 30.9881 82.3552 2.31592C75.738 10.6346 64.8018 23.2591 71.1841 35.3611C73.4723 39.6987 78.0355 42.3014 83.0533 42.2803C88.3664 42.258 93.7538 37.864 95.148 33.9898Z"
        fill="#32B5AD"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.6753 2.10115C61.0021 30.1714 84.1307 38.7494 95.644 32.2098C96.4592 29.2447 95.858 24.0719 94.7952 21.7775C87.3396 32.2329 66.2706 25.3293 83.642 0.852539C83.3435 1.22583 82.9595 1.73349 82.6753 2.10115Z"
        fill="#008DD2"></path>
    </svg>
  );
}

const MainLogo = styled(Logo)`
  width: 164px;
  height: 90px;
`;

export default MainLogo;
