import React from 'react';
import { Column, Footer, ScrollToTopFab } from '../../components';
import { About, Advantages, Certificates, Header, Partners, Services } from './components';

export default function HomeScreen() {
  return (
    <Column>
      <Header />
      <About />
      <Advantages />
      <Services />
      <Certificates />
      <Partners />
      <Footer />

      <ScrollToTopFab />
    </Column>
  );
}
