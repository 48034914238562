import React from 'react';
import { BlockSeparator, Column, Flex, Layout } from '../../../../components';
import homeClasses from '../../home.module.css';
import classes from './Advantages.module.css';
import styled from 'styled-components';
import icon1 from '../../../../images/icon1.png';
import icon2 from '../../../../images/icon2.png';
import icon3 from '../../../../images/icon3.png';
import { LAPTOP_WIDTH, TABLET_WIDTH } from '../../../../constants';

const Container = styled(Layout)`
  padding: 90px 15px 0 15px;
  margin-bottom: 90px;
  flex-direction: column;
`;

const Content = styled(Flex)`
  flex-direction: row;
  align-items: flex-start;
  gap: 30px;
  padding-top: 50px;
  padding-bottom: 60px;
  @media (max-width: ${LAPTOP_WIDTH}px) {
    gap: 0;
    flex-wrap: wrap;
  }
  @media (max-width: ${TABLET_WIDTH}px) {
    flex-direction: row;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex: 1;
  padding: 30px;
  @media (max-width: ${LAPTOP_WIDTH}px) {
    padding: 25px;
    min-width: 400px;
  }
  &:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  }
  height: 100%;
  transition: box-shadow 0.5s ease 0s;
`;

export default function Advantages() {
  return (
    <Container>
      <BlockSeparator title={'ПРЕИМУЩЕСТВА'} />
      <Content>
        <Item>
          <img src={icon1} alt={'icon1'} className={classes.image} />
          <Column gap={20}>
            <div className={homeClasses.subtitle}>НАДЕЖНЫЙ ПОСТАВЩИК</div>
            <div className={homeClasses.text}>
              Одним из наших главных приоритетов является контроль качества на всех этапах от
              проектирования и производства до пуско-наладки объектов.
            </div>
          </Column>
        </Item>
        <Item>
          <img src={icon2} alt={'icon2'} className={classes.image} />
          <Column gap={20}>
            <div className={homeClasses.subtitle}>ОПТИМАЛЬНЫЕ ЦЕНЫ</div>
            <div className={homeClasses.text}>
              «Water Engineering» практикует гибкую ценовую политику и индивидуальный подход к
              каждому проекту.
            </div>
          </Column>
        </Item>
        <Item>
          <img src={icon3} alt={'icon3'} className={classes.image} />
          <Column gap={20}>
            <div className={homeClasses.subtitle}>ОПЫТ</div>
            <div className={homeClasses.text}>
              Весь персонал компании имеет большой практический опыт в области производства,
              поставки и монтажа оборудования в соответствии с отечественными и международными
              стандартами.
            </div>
          </Column>
        </Item>
      </Content>
    </Container>
  );
}
