import React from 'react';
import main1 from '../../../images/main1.jpg';
import { ParagraphWithPhoto } from '../../../components';

export default function About() {
  return (
    <ParagraphWithPhoto
      title={
        'ТОО «Water Engineering» – производитель оборудования для очистки и перекачки сточных вод'
      }
      description={
        'ТОО “Water Engineering” – Казахстанский производитель оборудования для очистки сточных вод. Наша компания осуществляет производство, монтаж и пуско-наладку оборудования для очистки хозяйственно- бытовых и промышленных сточных вод. Наши сотрудники имеют опыт работы в области производства и поставок оборудования в соответствии со стандартами и требованиями компании "Chevron".'
      }
      photo={main1}
    />
  );
}
