import React from 'react';
import classes from './styles.module.css';

export default function BlockSeparator({
  title,
  small = false
}: {
  title: string;
  small?: boolean;
}) {
  return (
    <div className={classes.container}>
      <div className={[classes.line, classes.short].join(' ')} />
      <div className={[classes.title, small ? classes.smallTitle : ''].join(' ')}>{title}</div>
      <div className={classes.line} />
    </div>
  );
}
